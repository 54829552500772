import styled from "styled-components";
import tw from "twin.macro";
import React from "react";

import BackgroundContainer from "./BackgroundContainer";
import bg_image from '../../images/photos/earliest_days.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {CarouselBox, Heading, Paragraph, TextBox} from "./Common";

export default () => {
  return (
      <BackgroundContainer
          image={bg_image}
          children={
              <div>
                  <Heading>
                      <span>History</span>
                  </Heading>
                  <TextBox>
                      <Paragraph>
                          Deptford Creek has a rich history of utopian community ideals, from the pirate sailors who commandeered vessels from the Creek to found communities in the Caribbean to the Oxford Arms Theatre (now the Birds Nest pub) which gave Theatre Arm its name, one of the earliest recorded places in London to host drag performances. However, in the 20th Century it had largely fallen into disuse, becoming a dumping ground behind a series of derelict industrial yards, barely known to anyone living in the area.
                      </Paragraph>
                  </TextBox>
                  <CarouselBox>
                      <div className="carousel-container">
                          <Carousel transitionTime={500} showThumbs={false} centerMode={true} centerSlidePercentage={101} autoPlay={true} dynamicHeight={false} infiniteLoop={true}>
                              <div>
                                  <img src="/photos/sky.jpg" />
                                  <p className="legend">The Creek seen by helicopter in 2011</p>
                              </div>
                              <div>
                                  <img src="/photos/pensive_4to3.png" />
                                  <p className="legend">Julian working out a problem</p>
                              </div>
                              <div>
                                  <img src="/photos/less_problematic_mud_4to3.jpg" />
                                  <p className="legend">A community member inspects the waterline of his barge in 2000</p>
                              </div>
                              <div>
                                  <img src="/photos/rail-river_4to3.png" />
                                  <p className="legend">Built in 1998, the Docklands Light Rail changed the Creek beyond recognition from the quiet waterway Julian and Jeannie moved to. The younger boaters, though, have never known anything else, and love its striking sweep through the landscape (and a bit of rain-shelter on deck!)
                                  </p>
                              </div>
                              <div>
                                  <img src="/photos/walking_the_plank_4to3.png" />
                                  <p className="legend">Walking the plank: visitors cross a makeshift gangplank to visit a new arrival in 2001</p>
                              </div>
                              <div>
                                  <img src="/photos/final_furlol.JPG" />
                                  <p className="legend">Final furlong: Converted lifeboat ‘Humbug’s new roof gets its first coat of paint </p>
                              </div>
                              <div>
                                  <img src="/photos/majestic_4to3.jpg" />
                                  <p className="legend">Sabine in her full glory</p>
                              </div>
                              <div>
                                  <img src="/photos/vanished_skyline.jpeg" />
                                  <p className="legend">The Creek in 2010, before flats were built along the East bank</p>
                              </div>
                              <div>
                                  <img src="/photos/gathering.jpg" />
                                  <p className="legend">The Creek at its most magical in the evening, shortly after the arrival of broads Cruiser ‘Aquarius’</p>
                              </div>
                              <div>
                                  <img src="/photos/2008.jpeg" />
                                  <p className="legend">The end of theatre arm circa 2008, two of these boats have now left, and the third changed hands several times, but the community is continuous.</p>
                              </div>
                              <div>
                                  <img src="/photos/2010_4to3.png" />
                                  <p className="legend">The Creek from the air in 2010</p>
                              </div>
                          </Carousel>
                      </div>
                  </CarouselBox>
                  <TextBox>
                      <Paragraph>
                          That changed in 1987, when Julian Kingston moved in with his boat Sabine and his four-year-old son Josh. After clearing several tons of scrap from the bed of the Creek, Julian moored Sabine up, arranging to fill his water tanks at the Oxford Arms and generating his own power. In 1996 they were joined by Jeannie Seymour, who moved aboard Sabine and married Julian the following year. Julian and Jeannie were heavily involved in the Creekside Environment Project set up that year by Gill Goddard of Lewisham Council, and succeeded in making many environmental improvements to the Creek and many firm friendships among the locals.
                      </Paragraph>
                      <Paragraph>
                          Over the years, other like-minded boaters found their way up the Creek and moored up along the river wall and alongside one another. Various communities took shape at various points, but the stretch of the Theatre Arm accessed via No.2 Creekside, where Julian had originally moored up in 1986 to do work on his boat, slowly but surely evolved into a tight community. Boats have been repeatedly sold at a fraction of their worth to ensure that people who were already part of the community were able to continue living there as families grew and more space was needed, and more experienced members have lent a hand to younger boaters struggling to convert dilapidated lifeboats into tiny homes.
                      </Paragraph>
                      <Paragraph>
                          The community has campaigned hard to remain in place, sometimes in the face of seemingly insurmountable odds. As voluntary Council Tax payers, we have liaised with our Local MP to make sure our unique legal status and contribution is understood by local developers, getting recognised in the Local Area Development play as an intrinsic part of Deptford’s character. We try to always engage with others in a spirit of understanding, and this has paid off well: in 2019, local developers bought the yard at No.2 Creekside in the belief that we had no legal right to be there and could be removed: four years later we are working with them on  development options for the yard, as part of which we will be purchasing the section of the yard closest to the Creek and developing it into sustainably-powered community resources for the moorings!
                      </Paragraph>
                      <Paragraph>
                          Some community members have moved on, but still come back to visit. Others have moved between boats, moved away and come back, or are just starting their boating adventure. We are part of a much wider community of boaters, activists, tradesfolk and well-wishers. All of us are united, though, in the belief that what our community offers is beautiful, valuable and has a lot to offer the world.
                      </Paragraph>
                  </TextBox>
              </div>
          }
      />
  );
};

