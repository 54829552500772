import React from "react";
import BackgroundContainer from "./BackgroundContainer";
import bg_image from "../../images/photos/synergy.jpg";
import {Heading, Paragraph, StyledLink, SubHeading, TextBox} from "./Common";
import {Carousel} from "react-responsive-carousel";

export default () => {
    return (
        <BackgroundContainer
            image={bg_image}
            children={
                <div>
                    <Heading>
                        <span>Projects</span>
                    </Heading>
                    <TextBox>
                        <SubHeading>
                            Purchase and development of strip of land alongside the riverwall
                        </SubHeading>
                        <Paragraph>
                            After lengthy negotiations with a local developer, we are excited to announce that we have signed a contract to purchase the strip of dry land adjacent to the moorings! In this space we will be able to set up wood- and metalworking areas for improvements to our boats, as well as green waste-treatment solutions (including the alarming-sounding but very cool high-speed composters), pollinator-support strategies, and eventually a shore-mounted solar array to allow more ambitious power use for maintenance without having to resort to the National Grid.
                            Furthermore, in our close workings with Artworks LLP, who will be developing the rest of the yard, we have been able to advocate for development strategies which enhance rather than erase the character of our part of Deptford, such as recognition of Deptford’s established artistic heritage. There is still a lot of work to do to bring this exciting project to conclusion, but once that point is reached it will serve as a springboard to realise many of our most cherished goals.
                        </Paragraph>
                    </TextBox>
                    <TextBox>
                        <SubHeading>
                            Acquisition of a modular pontoon to allow easier stewardship of the ecosystem
                        </SubHeading>
                        <Paragraph>
                            Removing drifting rubbish from around our boats is an inherent part of life and a sisyphean task. We try to get out regularly, often by kayak or in a work boat, to get the rubbish that has drifted beyond reach of a well-handled boat-hook, but these missions take planning and timing (the tide, famously, waits for no-one) and are limited to a few people and the small amount of rubbish that can be towed behind a kayak or put in a work boat. The most common question is ‘can’t you just put your waders on..?’ which seems logical enough, given how often we have to get down in the mud around the boats to lift out debris or maintain the berths. Surprisingly the answer is actually no: this would do more environmental harm than good.
                        </Paragraph>
                        <Paragraph>
                            The mud of the Creek is an amazingly rich ecosystem: pull up any shovel-full and you’ll see almost as many tubifex worms as mud, to name just one of the many species of invertebrate which underpin the immense biodiversity of the tidal creek. These species rely on the mud remaining mostly undisturbed, to preserve the delicate balance of conditions that allows them to live and thrive. Churning up the mud is a risk to them, which is why we try to keep it to a minimum when not absolutely unavoidable, only disturb small areas at a time, and leave the ecosystem as long as possible between disturbances, as well as only using eco-friendly cleaning products and being responsible about waste disposal.
                        </Paragraph>
                        <Paragraph>
                            The amount of disturbance to the mud involved in wading across to reach the far-flung flotsam would cause harm to the marine ecosystem which would outweigh the benefits of removing even quite a lot of rubbish. Additionally, the depth and viscosity of the mud, while an indication of the health of the ecosystem, makes wading through it too risky for small groups to do alone, or to involve people who aren’t seasoned mud-dwellers.
                        </Paragraph>
                        <Paragraph>
                            Therefore we are applying for funding for a tideway-appropriate working pontoon, made up of modules which can be configured afloat, and then allowed to settle on the mud, allowing us safe access to remove surface rubbish while leaving the invertebrates to their business.
                        </Paragraph>
                        <Paragraph>
                            With any luck, we should be able to proceed with this within the next few months, making an exciting interim project we can get started on without waiting for the land purchase.
                        </Paragraph>
                    </TextBox>
                    <TextBox>
                        <SubHeading>
                            Bee hotel
                        </SubHeading>
                        <Paragraph>
                            Solitary bees constitute the majority of bee species, and there is growing evidence indicating a significant decline in their populations. Solitary bee species, such as the mason bee, are particularly at risk in the UK and require dedicated conservation efforts to ensure their survival. Furthermore, pollinators often exhibit specific preferences for the plants they visit, making the loss of solitary bee species detrimental to ecosystem health and biodiversity.
                        </Paragraph>
                        <Paragraph>
                            Deptford Creek boasts remarkable biodiversity, with over 150 plant species already supporting a honeybee hive. However, the area has relatively few private gardens, and to the best of our knowledge, lacks any existing solitary bee nests. Thus, our project aims to fill this gap and provide a suitable nesting site for solitary bees.
                        </Paragraph>
                        <Paragraph>
                            While numerous commercially available solitary beehives exist, they suffer from significant flaws. The primary issue lies in the necessity of cleaning these hives every 1-2 years to prevent parasite accumulation: due to the time-consuming nature of this task, cleaning is often neglected, leading to the accumulation of parasites. The net impact of these hives on bee populations remains uncertain, but they undoubtedly pose substantial challenges.
                        </Paragraph>
                        <Paragraph>
                            A striking example of the limitations of these hives is evident in the introduction of Beebricks in Brighton, intended to aid mason bees. The design flaw of inadequate hole depth for nesting bees and the potential for parasite accumulation were immediately pointed out upon <StyledLink href="https://www.theguardian.com/environment/2022/jan/18/brighton-bee-bricks-initiative-may-do-more-harm-than-good-say-scientists">their announcement</StyledLink>. While natural nest sites do exist, their scarcity in urban environments further necessitates the development of alternative solutions.
                        </Paragraph>
                        <Paragraph>
                            To address these challenges, we propose constructing a log store that will serve as a regularly cycled-through solitary bee nesting site. Properly drying logs for burning, with minimal air pollution, requires approximately one year. By utilizing freshly cut wood with drilled holes, we can cycle through the wood before parasites can accumulate, providing a suitable nesting environment for solitary bees.
                        </Paragraph>
                        <Paragraph>
                            Our approach involves drilling a single hole, 15cm deep, of various diameters per log/wood to accommodate different insect species. Capped cells during winter can be placed in a box with drilled holes to allow solitary bees to escape but prevent them from re-nesting in the same hole. The nesting area will be situated between 1m and 2m above the ground. Empty logs, after drying for one year, can be utilized for burning, ensuring that those of the boats which are still reliant on wood stoves are burning natural wood without creosote or other chemicals harmful to the ecosystem.
                        </Paragraph>
                        <Paragraph>
                            Our proposed solution offers a new and effective type of hive, potentially expanding access to these hives for a broader range of individuals. Additionally, the log store will support other beneficial insects, such as specific wasp species. Given that log stores require periodic checks, integrating bee nesting sites within them proves mutually beneficial and requires minimal additional effort. We also aim to collaborate with Lewisham Council to collect discarded Christmas trees after the holiday season, reducing littering and obtaining additional resources for our project. Alternatively, even without a formal partnership it should be possible to simply pick these up from the surrounding area since they are generally dumped after Christmas. Moreover, <StyledLink href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/517572/Summary_results_of_the_domestic_wood_use_survey_.pdf">around 1.5 million homes in the UK use at least some wood burning for heating</StyledLink>. These likely have log stores that could be repurposed as bee nesting sites if our project proves successful. This solution has the potential to offer a cost-effective approach to supporting solitary bee populations.
                        </Paragraph>
                    </TextBox>
                </div>
            }
        />
    );
};

