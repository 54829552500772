import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "./style.css"

const Container = styled.div`
  ${tw`relative p-0 -mx-8 bg-center bg-cover h-full`}
  background-image: url("/photos/mattie_on_suffolk_scaled.png");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-brandBlueLight opacity-50`;

const HeroContainer = tw.div`z-20 pt-24 relative px-4 sm:px-8 max-w-screen-xl mx-auto h-full`;

const Heading = styled.h1`
  ${tw`top-0 text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-brandPurpleDark leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SubHeading = styled.h2`
  ${tw`text-3xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl font-black text-brandPurpleDark leading-none`}
  span {
    ${tw`inline mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2 my-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;


export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <Heading>
          <span>Crossbones Co-op</span>
          <br />
          <SlantedBackground>
            <SubHeading>
              Sustainable Community Moorings.
            </SubHeading>
          </SlantedBackground>
        </Heading>
      </HeroContainer>
    </Container>
  );
};
