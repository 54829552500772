import styled from "styled-components";
import tw from "twin.macro";
import React from "react";

import BackgroundContainer from "./BackgroundContainer";
import bg_image from '../../images/photos/eco_background.jpeg'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {CarouselBox, CarouselContainer, Heading, Paragraph, TextBox} from "./Common";

export default () => {
  return (
      <BackgroundContainer
          image={bg_image}
          children={
              <div>
                  <Heading>
                      <span>Environmental Stewardship</span>
                  </Heading>
                  <TextBox>
                      <Paragraph>
                          Years before the establishment of the <a href="https://www.creeksidecentre.org.uk">Creekside Centre</a>, the boaters of Deptford Creek were taking active steps to protect the Creek ecosystem. The Creekside Environment Project, set up in 1996 as a collaboration between boaters, Lewisham Council, and local environmentalists, was half driven by Creek boaters, and was instrumental not only in initiatives to remove rubbish from the river, but in the installation of horizontal timbers between the vertical fenders of the creek (encouraging plant growth and providing nesting sites for birds), and in putting pressure on developers at the mouth of the Creek to incorporate sustainable design features into their buildings.
                      </Paragraph>
                  </TextBox>
                  <CarouselBox>
                      <CarouselContainer>
                          <Carousel transitionTime={500} showThumbs={false} centerMode={true} centerSlidePercentage={101} autoPlay={true} dynamicHeight={false} infiniteLoop={true}>
                              <div>
                                  <img src="/photos/winter.jpg" />
                                  <p className="legend">Boaters understand better than anyone the importance of good insulation: if these decks weren’t well-lined there would be as much water inside as outside from condensation!</p>
                              </div>
                              <div>
                                  <img src="/photos/synergy_4to3.png" />
                                  <p className="legend">The engine room on Sabine is a fusion of still-functional technology from the nineteenth century and cutting-edge electrical distribution technology</p>
                              </div>
                              <div>
                                  <img src="/photos/litter_pick_4to3.jpg" />
                                  <p className="legend">Litter-picking on the Creek often needs more than a bin-bag…</p>
                              </div>
                              <div>
                                  <img src="/photos/spring_cleaning.jpg" />
                                  <p className="legend">Rubbish from the creek collected on work boats after a community clean-up</p>
                              </div>
                              <div>
                                  <img src="/photos/between_sheets.JPG" />
                                  <p className="legend">A makeshift shelter rigged over converted lifeboat ‘Humbug’ while the roof is rebuilt.</p>
                              </div>
                              <div>
                                  <img src="/photos/under_wraps.jpg" />
                                  <p className="legend">Under wraps: a temporary shell built around broads cruiser ‘Aquarius’ so painting and sanding can take place without polluting the Creek.</p>
                              </div>
                              <div>
                                  <img src="/photos/grand_beep_cherokeeper_4to3.png" />
                                  <p className="legend">Julian’s bees make delicious, unique-tasting honey, and help keep the Creek plants pollinated.</p>
                              </div>
                          </Carousel>
                      </CarouselContainer>
                  </CarouselBox>
                  <TextBox>
                      <Paragraph>
                          Fast forward to the present, and environmental activism on the Creek has matured in line with modern discourse around sustainability, to where we believe that our community can not only play a positive role in stewardship of our own Creek, but also serve as a dynamic template for community-based models of sustainable living. Coming from a wide range of backgrounds, we all agree that community is too often the missing jigsaw piece in sustainable living: in our community, the individual boats are small, but the community and our connection to nature (through the seasonal and tidal rhythms of the Creek, and through the birds, bees and plant life we get to see every day) makes ‘home’ a far larger place than any individual boat, and leads organically to a lifestyle which avoids many of the traps of modern-day consumerism.
                      </Paragraph>
                      <Paragraph>
                          The basic mechanics of life afloat also lead naturally to a culture of resource conservation. When your power comes from batteries charged from solar panels, and your water comes from a tank, being ‘efficient’ with power is something you do for your own benefit, and you quickly learn how much power or water any given thing uses, and find workarounds to use less while still living well. In the same way, the difference between ‘eco’ cleaning products is very apparent when you can see first hand the impact they have on the plant and animal life in the Creek. This ‘insider knowledge’ of how to actually live sustainably can be useful to anyone in any living situation, and is just one of many ways in which organised communities of boaters can contribute to environmental concerns far beyond the waterways.
                      </Paragraph>
                      <Paragraph>
                          We haven’t lost sight of more local concerns, either. We routinely collect rubbish out of the creek from around our own boats floating rubbish with kayaks at high tide, and are currently fundraising for a modular pontoon which will allow us to remove larger pieces of litter from further out in the Creek without disturbing the rich ecosystem of the mud. Members of our community recently noticed oil in the water, and were able to trace it to a leak from an installation further down the Creek and alert the relevant parties. Many of us work in the boating industry, and our combined skillsets (welders, low-voltage electricians and marine heating and systems engineers to name but a few) mean that we are powerfully equipped to design systems for land-based shared sustainable resources, which we will be seeking funding for in the next cycle with a view to installation once we have completed purchase of the land at 2 Creekside. One of our members keeps honey bees, and one of our affiliates is advising us about the best way to create habitat for solitary bees.
                      </Paragraph>
                  </TextBox>
              </div>
          }
      />
  );
};

