export const Colours = {
    white: '#fff',
    black: '#000',
    brandTeal: '#0b5a7d',
    brandPurple: '#5c198b',
    brandPurpleDark: '#2d0748',
    brandWhite: '#e5feff',
    brandBlue: '#2ad0f3',
    brandYellow: '#f6c759',
    // your colors
}