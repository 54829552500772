import React, { useState } from "react";
import tw from "twin.macro";
import {Paragraph} from "./Common";
import "./style.css"
import styled from "styled-components";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/eb2a3940-15de-11ee-8025-97a9fb2f29da";

const ContactForm = () => {
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const inputs = e.target.elements;
        const data = {};

        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].name) {
                data[inputs[i].name] = inputs[i].value;
            }
        }

        fetch(FORM_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    return (
                        <Paragraph>
                            Something went wrong. Please try again later
                        </Paragraph>
                    )
                }

                setSubmitted(true);
            })
            .catch((e) => {
                // Submit the form manually
                e.target.submit();
            });
    };

    if (submitted) {
        return (
            <>
                <div className="text-2xl">Thank you!</div>
                <div className="text-md">We'll be in touch soon.</div>
                {/*<Paragraph>*/}
                {/*    Thank you!*/}
                {/*</Paragraph>*/}
                {/*<Paragraph>*/}
                {/*    We'll be in touch soon.*/}
                {/*</Paragraph>*/}
            </>
        );
    }

    const FormInput = tw.div`my-4 w-10/12`

    const StyledInput = styled.input`
      ${tw`px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full`}
    `;

    const StyledTextarea = styled.textarea`
      ${tw`px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full h-72`}
    `;

    const StyledButton = styled.button`
      ${tw`bg-brandPurple text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
    `

    return (
        <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            className="contact-form"
        >
            <FormInput>
                <StyledInput
                    type="text"
                    placeholder="Your name"
                    name="name"
                    required
                />
            </FormInput>
            <FormInput>
                <StyledInput
                    type="email"
                    placeholder="Email"
                    name="email"
                    required
                />
            </FormInput>
            <FormInput>
                <StyledTextarea
                    placeholder="Your message"
                    name="message"
                    required
                />
            </FormInput>
            <div className="mb-3 pt-0">
                <StyledButton
                    type="submit"
                >
                    Send a message
                </StyledButton>
            </div>
        </form>
    );
};

export default ContactForm;