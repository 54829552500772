import styled from "styled-components";
import tw from "twin.macro";
import React from "react";

import BackgroundContainer from "./BackgroundContainer";
import bg_image from '../../images/photos/community_ethos_bg.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {Heading, Paragraph, TextBox, StyledLink, CarouselBox} from "./Common";


export default () => {
  return (
      <BackgroundContainer
          image={bg_image}
          children={
              <div>
                  <Heading>
                      <span>Community Ethos</span>
                  </Heading>
                  <TextBox>
                      <Paragraph>
                          The main strength of our community is its diversity. Our oldest member is 76, our youngest eight months. Most of us work or have worked in marine or marine-adjacent trades, and those who haven’t have all been involved in other sorts of community organising or community-centred work. One of our main drives is the nurturing of the boater ethos, where people are skilled up and empowered to understand the workings of their own vessels as well as how they sit within the community and the ecosystem: when the same people are living on the Creek, looking after the Creek and looking after the boats, the system works better and is fairer and more sustainable.
                      </Paragraph>
                  </TextBox>
                  <CarouselBox>
                      <div className="carousel-container">
                          <Carousel transitionTime={500} showThumbs={false} centerMode={true} centerSlidePercentage={101} autoPlay={true} dynamicHeight={false} infiniteLoop={true}>
                              <div>
                                  <img src="/photos/strawberries_4to3.png" />
                                  <p className="legend">A volunteer clearing rubbish from around Lea Barge ‘Luna’ notices she is being photographed</p>
                              </div>
                              <div>
                                  <img src="/photos/sparks_4to3.png" />
                                  <p className="legend">Many of the boats on the creek were someone’s first home and first chance to learn building skills; here, Jay White rebuilds the front deck of their boat Humbug</p>
                              </div>
                              <div>
                                  <img src="/photos/mud_4to3.jpg" />
                                  <p className="legend">A volunteer from California checks the mud after helping to settle tug ‘Sulby’ into a temporary berth</p>
                              </div>
                              <div>
                                  <img src="/photos/joinery_4to3.png" />
                                  <p className="legend">The beautiful woodwork of Suffolk of Stalham has been lovingly restored by Mat Kennedy and his father Mick, in the process providing training in carpentry to many of the younger boaters</p>
                              </div>
                              <div>
                                  <img src="/photos/ta_da.JPG" />
                                  <p className="legend">A former berth-holder posing while teaching newer members of the community how to weld</p>
                              </div>
                              <div>
                                  <img src="/photos/fibonacci_deck.jpg" />
                                  <p className="legend">The beauty of the creek is found in so many unexpected places, like this picture of cruiser ‘Suffolk of Stalham’s deck being rebuild</p>
                              </div>
                              <div>
                                  <img src="/photos/companionship_4to3.jpg" />
                                  <p className="legend">Former berth-holder David Williams enjoys a quiet moment with Catso, who still lives here</p>
                              </div>
                              <div>
                                  <img src="/photos/sweeping.jpg" />
                                  <p className="legend">a view of the DLR from the deck of Humber Keel ‘Thomas Waudby’, showing ‘Suffolk of Stalham’ sheeted up for work</p>
                              </div>
                          </Carousel>
                      </div>
                  </CarouselBox>
                  <TextBox>
                      <Paragraph>
                          We are also committed to ensuring that the Creek remains a place where people can be introduced to a boating lifestyle that does not require inherited resources and privilege. The boater ethos is part of this: boating is affordable if you can fix your own boat, and you can learn to fix your own boat if there are community members who can show you how. We try to take this one step further: three of our youngest community members are currently learning boat related trades as apprentice-labourers via contacts of our members who already run their own marine-adjacent businesses. Our aim is that all our members should have the option to learn not only how to maintain and repair their own boats, but how to make a living in the boating world, as this strengthens both our own community and our ties to wide boating networks in London and further afield. Our Articles of Association stipulate that the rent for access across the land must remain affordable, and that the boats cannot be sold with automatic mooring rights, so that our development of the moorings cannot simply lead to boats being sold ‘on the mooring’ at ever-increasing prices.
                      </Paragraph>
                      <Paragraph>
                          That is not to say we do not try to improve the moorings and enrich our lives. Initiatives such as the Workaway program have led to people from around the world coming to live and help out on the boats, and our little community is part of an ever expanding network of communities and individuals passionate about the things we represent. Our members have ties not only to environmental and sustainability movements but to historic boat restoration movements such as <StyledLink href="http://www.buildthelenox.org">The Lenox Project</StyledLink>, as well as ties with other boating communities across the UK. We are currently working with <StyledLink className="inline-link" href="https://www.uk.coop">Co-operatives UK</StyledLink> towards setting up a network of Co-operatively-run boating communities, with a view to unlocking even more potential for social good by bringing together more skilled, like-minded people within a framework where skills can be shared and challenges faced together.
                      </Paragraph>
                  </TextBox>
              </div>
          }
      />
  );
};

