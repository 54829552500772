import React from "react";
import "tailwindcss/lib/css/preflight.css"
import tw from "twin.macro";
import styled from "styled-components";
import "./style.css"

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import FrontPage from "components/custom/FrontPage"
import About from "components/custom/About"
import EnvironmentalStewardship from "components/custom/EnvironmentalStewardship"
import CommunityEthos from "components/custom/CommunityEthos"
import History from "components/custom/History"
import Projects from "components/custom/Projects"
import Contact from "components/custom/Contact"


import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import { ReactComponent as Logo } from "images/logo.svg";
import {Colours} from "components/custom/Colours";

const StyledHeader = styled(Header)`
  ${tw`relative inset-0 top-0 h-full z-50 pt-8 max-w-screen-xl`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = tw.div`fixed p-0 -mt-8 bg-center bg-cover h-screen w-screen`

const ContentContainer = tw.div`relative my-24 p-0 m-0 w-full h-full`

const NavWrapper = styled.div`
  ${tw`z-40 mx-0 px-10 absolute inset-0 h-24 bg-brandPurple w-screen`}
`;

function App() {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about" className="uncollapsible">
        About
      </NavLink>
      <NavLink href="/environmental_stewardship" className="uncollapsible">
        Environmental Stewardship
      </NavLink>
      <NavLink href="/community_ethos" className="uncollapsible">
        Community Ethos
      </NavLink>
      <NavLink href="/history" className="uncollapsible">
        History
      </NavLink>
      <NavLink href="/projects" className="uncollapsible">
        Projects
      </NavLink>
      <NavLink href="/contact" className="uncollapsible">
        Contact Us
      </NavLink>
    </NavLinks>,
  ];
  const ccLogo = (
      <LogoLink href="/">
        <Logo fill={Colours.white} height="100%"/>
        <p className="uncollapsible">Crossbones Co-op</p>
      </LogoLink>
  )

  const router = createBrowserRouter([
    {
      path: "/",
      element: <FrontPage/>
    },
    {
      path: "/about",
      element: <About/>
    },
    {
      path: "/environmental_stewardship",
      element: <EnvironmentalStewardship/>
    },
    {
      path: "/community_ethos",
      element: <CommunityEthos/>
    },
    {
      path: "/projects",
      element: <Projects/>
    },
    {
      path: "/history",
      element: <History/>
    },
    {
      path: "/contact",
      element: <Contact/>
    },
  ]);



  return (
      <Container>
        <NavWrapper>
          <StyledHeader links={navLinks} logoLink={ccLogo}/>
        </NavWrapper>
        <ContentContainer>
          <RouterProvider router={router} />
        </ContentContainer>
      </Container>
  );
};

export default App