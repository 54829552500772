import styled from "styled-components";
import tw from "twin.macro";

const Heading = styled.h1`
  ${tw`top-0 text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-brandPurpleDark leading-none`}
  span {
    ${tw`inline-block mt-2 mb-16`}
  }
`;

const SubHeading = styled.h2`
  ${tw`my-8 text-xl text-left lg:text-left sm:text-xl lg:text-2xl xl:text-3xl font-black text-brandPurpleDark leading-none`}
  span {
    ${tw`inline mt-4`}
  }
`;

const TextBox = tw.div`rounded-xl bg-gray-100 bg-opacity-75 py-5 px-8 sm:px-20 mb-10`
const CarouselBox = tw.div`rounded-xl bg-gray-100 bg-opacity-75 py-5 px-0 sm:px-20 mb-10`
const Paragraph = tw.p`text-brandPurpleDark w-full mb-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

const CarouselContainer = tw.div`sm:w-10/12 w-full flex mx-auto`

const StyledLink = tw.a`text-brandTeal underline`;

export { Heading }
export { SubHeading }
export { TextBox }
export { CarouselBox }
export { Paragraph }
export { CarouselContainer }
export { StyledLink }
