import React from "react";
import BackgroundContainer from "./BackgroundContainer";
import bg_image from "../../images/photos/gathering.jpg";
import {CarouselBox, Heading, Paragraph, TextBox} from "./Common";
import {Carousel} from "react-responsive-carousel";

export default () => {
    return (
        <BackgroundContainer
            image={bg_image}
            children={
                <div>
                    <Heading>
                        <span>About Us</span>
                    </Heading>
                    <TextBox>
                        <Paragraph>
                            Welcome to our unique home! Crossbones Co-operative is a community of liveaboard boaters moored in the Theatre Arm of Deptford Creek. We are committed to building a socially conscious, sustainable community able to safeguard and champion both the ecosystem of the Creek and the longstanding community-based lifestyle we represent, both on the local level and as part of a wider network of communities striving towards sustainable lifestyles accessible to all.
                        </Paragraph>
                    </TextBox>
                    <CarouselBox>
                        <div className="carousel-container">
                            <Carousel transitionTime={500} showThumbs={false} centerMode={true} centerSlidePercentage={101} autoPlay={true} dynamicHeight={false} infiniteLoop={true}>
                                <div>
                                    <img src="/photos/mud_berth.jpg" />
                                    <p className="legend">Boaters check a the bed of the creek for debris between tides, before moving a boat onto a new berth</p>
                                </div>
                                <div>
                                    <img src="/photos/rainbow_4to3.png" />
                                    <p className="legend">A perfect day on the creek</p>
                                </div>
                                <div>
                                    <img src="/photos/serene.jpg" />
                                    <p className="legend">A serene moment in the evening</p>
                                </div>
                                <div>
                                    <img src="/photos/waudby.jpg" />
                                    <p className="legend">A sweeping view of Humber Keel ‘Thomas Waudby’ from the river wall</p>
                                </div>
                            </Carousel>
                        </div>
                    </CarouselBox>
                    <TextBox>
                        <Paragraph>
                            We aim to continuously improve as a community, as well as developing our moorings, our networks, and the skills both within our community and accessible to us via those networks, to live sustainably ourselves and encourage sustainable practices in the local area and beyond by sharing the skills, insights and environmental literacy we have cultivated through our unique combination of trade skills, compact-living lifestyles and backgrounds in various forms of environmental and community activism. Through nurturing the holistic ‘boater ethos’ of community-grounded self-reliance and a DIY connection both to the boats themselves and to the tidal land on which they sit, we believe we can develop our way of life into something which has applications far beyond life afloat, working with local organizations and expanding networks across the country and the world to put the focus on community as the all-too-often-missing piece of the sustainability puzzle.
                        </Paragraph>
                        <Paragraph>
                            Thank you for joining us on our journey!
                        </Paragraph>
                    </TextBox>
                </div>
            }
        />
    );
};

