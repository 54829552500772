import React, {useState}  from "react";
import BackgroundContainer from "./BackgroundContainer";
import bg_image from "../../images/photos/rainbow.jpg";
import {Heading, Paragraph, TextBox} from "./Common";
import {Carousel} from "react-responsive-carousel";
import ContactForm from "./ContactForm";

export default () => {
    return (
        <BackgroundContainer
            image={bg_image}
            children={
                <div>
                    <Heading>
                        <span>Contact Us</span>
                    </Heading>
                    <TextBox>
                        <Paragraph>
                            Any questions or ideas? Write to us!
                        </Paragraph>
                        <ContactForm/>
                    </TextBox>
                </div>
            }
        />
    );
};

