import styled from "styled-components";
import tw from "twin.macro";
import React from "react";

export default ({children, image}) => {
    const Container = styled.div`
      ${tw`relative p-0 -mx-8 overflow-scroll bg-center bg-cover h-full`}
      background-image: url(${image});
    `;

    const OpacityOverlay = tw.div`z-10 fixed inset-0 m-0 bg-brandBlue opacity-50`;

    const HeroContainer = tw.div`z-20 pt-24 relative px-4 pb-20 sm:px-8 max-w-screen-xl mx-auto h-full`;

    const FooterBuffer = tw.div`my-8 h-16 block`;

    return (
        <Container>
            <OpacityOverlay />
            <HeroContainer>
                {children}
                <FooterBuffer/>
            </HeroContainer>
        </Container>
    );
};
